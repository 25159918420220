/* You can add global styles to this file, and also import other style files */
@import '~ngx-owl-carousel-o/lib/styles/prebuilt-themes/owl.carousel.min.css';
@import '~ngx-owl-carousel-o/lib/styles/prebuilt-themes/owl.theme.default.min.css';
@font-face {
    font-family: "OpenSans";
    src: url("../src/assets/fonts/OpenSans-Regular.ttf");
}

body{
    font-family: "OpenSans";
}
